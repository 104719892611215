import React, { createContext, useContext, useState } from 'react';

const SelectedImagesContext = createContext();

export const SelectedImagesProvider = ({ children }) => {
  const [selectedImages, setSelectedImages] = useState([]);

  return (
    <SelectedImagesContext.Provider value={{ selectedImages, setSelectedImages }}>
      {children}
    </SelectedImagesContext.Provider>
  );
};

export const useSelectedImages = () => useContext(SelectedImagesContext);
