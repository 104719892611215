import React, { createContext, useContext, useState } from 'react';

const PaymentOptionContext = createContext();

export const PaymentOptionProvider = ({ children }) => {
  const [selectedOption, setSelectedOption] = useState('Standard');

  return (
    <PaymentOptionContext.Provider value={{ selectedOption, setSelectedOption }}>
      {children}
    </PaymentOptionContext.Provider>
  );
};

export const usePaymentOption = () => useContext(PaymentOptionContext);
