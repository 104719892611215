import React, { createContext, useContext, useState } from 'react';

const UserRequestContext = createContext();

export const UserRequestProvider = ({ children }) => {
  const [userRequestData, setUserRequestData] = useState({
    gender: '',
    photoAge: '',
    desiredAge: '',
    desiredStyle: '',
    additionalInfo: '',
    phoneNumber: '',
    email: '',
    preferredContactMethod: '',
    shippingAddress: '',
  });

  return (
    <UserRequestContext.Provider value={{ userRequestData, setUserRequestData }}>
      {children}
    </UserRequestContext.Provider>
  );
};

export const useUserRequest = () => useContext(UserRequestContext);
