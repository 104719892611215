/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { useSelectedImages } from '../contexts/SelectedImagesContext';

const UserSelect = () => {
  const { selectedImages, setSelectedImages } = useSelectedImages();
  const [selectedGender, setSelectedGender] = useState('');

  const maleImages = [
    '/images/male1.jpg',
    '/images/male2.jpg',
    '/images/male3.jpg',
    '/images/male4.jpg',
    '/images/male5.jpg',
    '/images/male6.jpg',
    '/images/male7.jpg',
    '/images/male8.jpg',
    '/images/male9.jpg',
    '/images/male10.jpg',
  ];

  const femaleImages = [
    '/images/female1.jpg',
    '/images/female2.jpg',
    '/images/female3.jpg',
    '/images/female4.jpg',
    '/images/female5.jpg',
    '/images/female6.jpg',
    '/images/female7.jpg',
    '/images/female8.jpg',
    '/images/female9.jpg',
    '/images/female10.jpg',
  ];

  const handleGenderSelect = (gender) => {
    setSelectedGender(gender);
    setSelectedImages([]); // Reset selected images when gender is changed
  };

  const handleImageClick = (image) => {
    if (selectedImages.length >= 4 && !selectedImages.includes(image)) {
      return;
    }
    if (selectedImages.includes(image)) {
      setSelectedImages(selectedImages.filter((img) => img !== image));
    } else {
      setSelectedImages([...selectedImages, image]);
    }
  };

  const images = selectedGender === '남자' ? maleImages : femaleImages;

  return (
    <Container>
      {!selectedGender ? (
        <>
          <Title>생성할 이미지의 성별</Title>
          <GenderSelection>
            <GenderButton onClick={() => handleGenderSelect('남자')}>남자</GenderButton>
            <GenderButton onClick={() => handleGenderSelect('여자')}>여자</GenderButton>
          </GenderSelection>
        </>
      ) : (
        <>
          <Title>원하시는 이미지를 선택해주세요</Title>
          <ImageGrid>
            {images.map((image, index) => (
              <ImageWrapper key={index} onClick={() => handleImageClick(image)}>
                <Image
                  src={image}
                  alt={`이미지 ${index + 1}`}
                  isSelected={selectedImages.includes(image)}
                />
              </ImageWrapper>
            ))}
          </ImageGrid>
          <FixedFooter>
            <SelectedImagesContainer>
              {selectedImages.map((image, index) => (
                <SelectedImageWrapper key={index} onClick={() => handleImageClick(image)}>
                  <SelectedImage src={image} alt={`선택된 이미지 ${index + 1}`} />
                </SelectedImageWrapper>
              ))}
            </SelectedImagesContainer>
            <StyledLink to='/userrequest' disabled={selectedImages.length !== 4}>
              <HomeButton disabled={selectedImages.length !== 4}>다음</HomeButton>
            </StyledLink>
          </FixedFooter>
        </>
      )}
    </Container>
  );
};

export default UserSelect;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  position: relative;
  min-height: 100vh;
`;

const GenderSelection = styled.div`
  display: flex;
  gap: 20px;
`;

const GenderButton = styled.button`
  padding: 20px;
  font-size: 20px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  &:hover,
  &:active {
    opacity: 0.8;
  }
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 15px;
  width: 100%;
  max-width: 800px;
  margin-bottom: 80px;
`;

const ImageWrapper = styled.div`
  cursor: pointer;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  border: ${(props) => (props.isSelected ? '3px solid #000' : '1px solid #ccc')};
  border-radius: 10px;
`;

const SelectedImagesContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
`;

const SelectedImageWrapper = styled.div`
  cursor: pointer;
`;

const SelectedImage = styled.img`
  width: 60px;
  height: 60px;
  border: 2px solid #000;
  border-radius: 10px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
`;

const HomeButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 11px;
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  &:hover,
  &:active {
    opacity: ${(props) => (props.disabled ? 0.5 : 0.8)};
  }
`;

const FixedFooter = styled.div`
  position: fixed;
  bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
