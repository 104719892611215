/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import './App.css';

import { AuthProvider, useAuth } from './contexts/AuthContext';
import { PaymentOptionProvider } from './contexts/PaymentOptionContext';
import { SelectedImagesProvider } from './contexts/SelectedImagesContext';
import { UserRequestProvider } from './contexts/UserRequestContext';

import Home from './Home';
import Success from './payments/Success';
import Fail from './payments/Fail';
import Album from './Album';
import LoginModal from './components/LoginModal';
// import Footer from './components/Footer';
import Header from './components/Header';
import ProtectedRoute from './ProtectedRoute';
import Settings from './Settings';
import Detail from './Detail';
import FAQ from './pages/FAQ';
import SubmitInfo from './pages/SubmitInfo';
import SubmitPhoto from './pages/SubmitPhoto';
import KakaoCallback from './components/KakaoCallback';
import UserRequest from './pages/UserRequest';
import UserSelect from './pages/UserSelect';

const AppContent = () => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const { user, loading } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const hideHeaderFooter = ['/detail', '/settings', '/faq', '/contact'].includes(location.pathname);

  useEffect(() => {
    if (user) setShowLoginModal(false);
  }, [user]);

  // const handleAlbumClick = () => {
  //   if (!user) setShowLoginModal(true);
  // };

  const closeLoginModal = () => {
    setShowLoginModal(false);
    navigate('/');
  };

  const showLoginModalHandler = () => setShowLoginModal(true);

  if (loading) return null;

  return (
    <>
      <div className={showLoginModal ? 'no-shadow' : ''}>
        {!hideHeaderFooter && <Header className='header' onLogoClick={closeLoginModal} />}
        <div
          className={`content ${showLoginModal ? 'overlay' : ''} ${
            hideHeaderFooter ? 'no-padding-top no-scroll' : ''
          }`}
        >
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/detail' element={<Detail />} />
            <Route path='/album' element={<ProtectedRoute element={<Album />} />} />
            <Route path='/success' element={<ProtectedRoute element={<Success />} />} />
            <Route path='/fail' element={<ProtectedRoute element={<Fail />} />} />
            <Route path='/submitinfo' element={<ProtectedRoute element={<SubmitInfo />} />} />
            <Route path='/submitphoto' element={<ProtectedRoute element={<SubmitPhoto />} />} />
            <Route path='/userrequest' element={<UserRequest />} />
            <Route path='/userselect' element={<UserSelect />} />
            <Route
              path='/settings'
              element={<Settings onShowLoginModal={showLoginModalHandler} />}
            />
            <Route path='/faq' element={<FAQ />} />
            {/* <Route path='/contact' element={<Contact />} /> */}
            <Route path='/kakao/callback' element={<KakaoCallback />} />
          </Routes>
        </div>
        <div>
          <div>상호명 : 대구정리수납 행복한 정리왕 유품정리 이사후 정리</div>
          <div>사업자등록번호 : 595-64-00607</div>
          <div>대표자명 : 박희란</div>
          <div>사업장 주소 : 대구광역시 북구 매전로4길 9, 201동 303호(매천동, 매천센트럴파크)</div>
          <div>연락처 : 010-5169-8305</div>
          {/* <div>통신판매업 신고번호 : </div> */}
        </div>
        {/* {!hideHeaderFooter && (
          <Footer
            className='footer'
            onHomeClick={closeLoginModal}
            onAlbumClick={handleAlbumClick}
          />
        )} */}
      </div>
      {showLoginModal && <LoginModal show={showLoginModal} onClose={closeLoginModal} />}
    </>
  );
};

export default function App() {
  return (
    <HelmetProvider>
      <AuthProvider>
        <PaymentOptionProvider>
          <SelectedImagesProvider>
            <UserRequestProvider>
              <Router>
                <AppContent />
              </Router>
            </UserRequestProvider>
          </SelectedImagesProvider>
        </PaymentOptionProvider>
      </AuthProvider>
    </HelmetProvider>
  );
}
