/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Link, useLocation } from 'react-router-dom';
import SuccessIcon from '../assets/images/success.gif';
import { checkErrorCode } from '../utils';
import { usePaymentOption } from '../contexts/PaymentOptionContext';

const Success = () => {
  const location = useLocation();
  const { selectedOption } = usePaymentOption();
  const [paymentStatus, setPaymentStatus] = useState('pending'); // 'pending', 'success', 'error'
  const [errorMessage, setErrorMessage] = useState('');

  const queryParams = new URLSearchParams(location.search);
  const paymentKey = queryParams.get('paymentKey');
  const orderId = queryParams.get('orderId');
  const amount = queryParams.get('amount');

  useEffect(() => {
    const verifyPayment = async () => {
      try {
        const response = await fetch(
          'https://us-central1-ai-long-life.cloudfunctions.net/completePayment',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ paymentKey, orderId, amount }),
          }
        );

        const result = await response.json();
        console.log('데이터 확인', result);
        if (result.success) {
          console.log('결제 검증 성공:', result);
          setPaymentStatus('success');
        } else {
          console.error('결제 검증 실패:', result.error);
          if (checkErrorCode(result.error)) {
            console.log(
              '기존 요청을 처리 중이거나, 이미 사용된 주문번호입니다. 화면에 실패 메시지를 표시하지 않음.'
            );
          } else {
            setPaymentStatus('error');
            setErrorMessage(result.error || '알 수 없는 오류가 발생했습니다.');
          }
        }
      } catch (error) {
        console.error('결제 검증 요청 실패:', error);
        if (error.message.includes(checkErrorCode(error))) {
          console.log(
            '기존 요청을 처리 중이거나, 이미 사용된 주문번호입니다. 화면에 실패 메시지를 표시하지 않음.'
          );
        } else {
          setPaymentStatus('error');
          setErrorMessage(error.message || '결제 검증 중 오류가 발생했습니다.');
        }
      }
    };
    verifyPayment();
  }, [paymentKey, orderId, amount]);

  return (
    <Container>
      <IconWrapper>
        <IconImage src={SuccessIcon} alt='success_icon' />
      </IconWrapper>
      <Title>
        {paymentStatus === 'success' && '결제가 성공적으로 완료되었습니다!'}
        {paymentStatus === 'error' && '결제 처리 중 오류가 발생했습니다.'}
        {paymentStatus === 'pending' && '결제 처리 중...'}
      </Title>
      {paymentStatus === 'error' && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {paymentStatus === 'success' && (
        <ButtonWrapper>
          {selectedOption === 'Deluxe' || selectedOption === 'Premium' ? (
            <StyledLink to='/userselect'>
              <HomeButton>다음</HomeButton>
            </StyledLink>
          ) : (
            <StyledLink to='/userrequest'>
              <HomeButton>다음</HomeButton>
            </StyledLink>
          )}
        </ButtonWrapper>
      )}
    </Container>
  );
};

export default Success;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #fff;
  padding: 16px;
  text-align: center;
`;

const IconWrapper = styled.div`
  margin-bottom: 20px;
`;

const IconImage = styled.img`
  width: 40px;
  height: 40px;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin: 16px 0;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const HomeButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 11px;
  cursor: pointer;
  &:hover,
  &:active {
    opacity: 0.8;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  margin-bottom: 20px;
`;
