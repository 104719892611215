import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { useUserRequest } from '../contexts/UserRequestContext';
import { useSelectedImages } from '../contexts/SelectedImagesContext';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebase/firebaseConfig';
import { doc, setDoc } from 'firebase/firestore';

const UserRequest = () => {
  const { userRequestData, setUserRequestData } = useUserRequest();
  const { selectedImages } = useSelectedImages();
  const { user } = useAuth(); // 현재 로그인한 사용자 정보 가져오기
  const [isFormValid, setIsFormValid] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserRequestData({
      ...userRequestData,
      [name]: value,
    });
    validateForm({ ...userRequestData, [name]: value });
  };

  const validateForm = (data) => {
    const isValid =
      data.gender &&
      data.photoAge &&
      data.desiredAge &&
      data.desiredStyle &&
      data.phoneNumber &&
      data.email &&
      data.preferredContactMethod &&
      (data.selectedOption !== 'Premium' || data.shippingAddress);
    setIsFormValid(isValid);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('asdas');
    if (!user) {
      console.error('로그인이 필요합니다.');
      return;
    }

    try {
      // Firestore의 해당 사용자 문서 업데이트
      const userDocRef = doc(db, 'users', user.uid);
      console.log(userDocRef);
      await setDoc(
        userDocRef,
        {
          userRequest: { ...userRequestData, selectedImages }, // FIXME: 선택된 이미지의 제목만 저장하도록 ...selectedImages/tile
        },
        { merge: true } // 기존 데이터에 병합
      );
      console.log('사용자 요청 정보가 업데이트되었습니다.');
    } catch (error) {
      console.error('사용자 요청 정보 업데이트 실패:', error);
    }
  };

  return (
    <Container>
      <Form>
        <Label>
          성별: <Required>*</Required>
        </Label>
        <Select name='gender' value={userRequestData.gender} onChange={handleChange} required>
          <option value=''>선택하세요</option>
          <option value='남자'>남자</option>
          <option value='여자'>여자</option>
        </Select>

        <Label>
          사진상의 연령대: <Required>*</Required>
        </Label>
        <Input name='photoAge' value={userRequestData.photoAge} onChange={handleChange} required />

        <Label>
          원하시는 결과물의 연령대: <Required>*</Required>
        </Label>
        <Input
          name='desiredAge'
          value={userRequestData.desiredAge}
          onChange={handleChange}
          required
        />

        <Label>
          원하시는 스타일: <Required>*</Required>
        </Label>
        <Input
          name='desiredStyle'
          value={userRequestData.desiredStyle}
          onChange={handleChange}
          required
        />

        <Label>기타:</Label>
        <Input
          name='additionalInfo'
          value={userRequestData.additionalInfo}
          onChange={handleChange}
        />

        <Label>
          폰번호: <Required>*</Required>
        </Label>
        <Input
          name='phoneNumber'
          value={userRequestData.phoneNumber}
          onChange={handleChange}
          required
        />

        <Label>
          이메일: <Required>*</Required>
        </Label>
        <Input name='email' value={userRequestData.email} onChange={handleChange} required />

        <Label>
          받으실 때 선호하는 방법 (폰, 이메일 중 선택): <Required>*</Required>
        </Label>
        <Select
          name='preferredContactMethod'
          value={userRequestData.preferredContactMethod}
          onChange={handleChange}
          required
        >
          <option value=''>선택하세요</option>
          <option value='폰'>폰</option>
          <option value='이메일'>이메일</option>
        </Select>

        {userRequestData.selectedOption === 'Premium' && (
          <>
            <Label>
              배송받으실 주소: <Required>*</Required>
            </Label>
            <Input
              name='shippingAddress'
              value={userRequestData.shippingAddress}
              onChange={handleChange}
              required
            />
          </>
        )}

        <StyledLink to='/submitinfo' disabled={!isFormValid} onClick={handleSubmit}>
          다음
        </StyledLink>
      </Form>
    </Container>
  );
};

export default UserRequest;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  max-width: 400px;
`;

const Label = styled.label`
  font-weight: bold;
`;

const Required = styled.span`
  color: red;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const Select = styled.select`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const StyledLink = styled(Link)`
  text-align: center;
  text-decoration: none;
  margin-top: 20px;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  padding: 15px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  &:hover {
    opacity: ${(props) => (props.disabled ? 0.5 : 0.8)};
  }
`;
